import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { formatCurrency } from '../../utils';

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ProductCommissionStrategiesList = props => {
  const classes = useStyles();
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField
          label="Commission settings"
          className={classes.flex}
          render={record => {
            const sortedSettings = JSON.parse(JSON.stringify(record.product_commission_strategy_settings)).sort(
              (a, b) => {
                if (+a.tenor_from !== +b.tenor_from) {
                  return +a.tenor_from - +b.tenor_from;
                }
                return +a.principal_from - +b.principal_from;
              },
            );
            return sortedSettings.map((setting, idx) => (
              <span key={idx}>
                <b>Tenors range: </b>
                {setting.tenor_from} - {setting.tenor_to} | <b>Principals range: </b>
                {setting.principal_from} - {setting.principal_to} |{' '}
                <b>{`Commission ${setting.commission_amount ? 'amount' : 'percent'}: `}</b>
                {setting.commission_amount
                  ? formatCurrency(setting.commission_amount)
                  : `${parseFloat((setting.commission_percent * 100).toFixed(10))} %`}
              </span>
            ));
          }}
        />
      </Datagrid>
    </List>
  );
};

export default ProductCommissionStrategiesList;
