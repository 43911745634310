import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { Form, Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

export const AnomalyTriggersSettings = ({ record = null, refreshedAt }) => {
  const [loanAnomalyNotifications, setLoanAnomalyNotifications] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider
      .getList('loan_anomaly_notifications', {
        filter: { 'loan.id': record.id },
        pagination: {},
        sort: {},
      })
      .then(({ data }) => setLoanAnomalyNotifications(data[0] || null))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify, record, refreshedAt]);

  const handleSubmit = values => {
    setLoading(true);
    loanAnomalyNotifications?.id
      ? dataProvider
          .update('loan_anomaly_notifications', {
            id: loanAnomalyNotifications.id,
            data: { _params: { method: 'PATCH' }, ...values },
          })
          .then(({ data }) => {
            setLoanAnomalyNotifications(data);
            notify('Settings successfully saved', 'success');
          })
          .catch(error => notify(`Error: ${error.message}`, 'error'))
          .finally(() => setLoading(false))
      : dataProvider
          .create('loan_anomaly_notifications', {
            data: { loan_id: record.id, ...values },
          })
          .then(({ data }) => {
            setLoanAnomalyNotifications(data);
            notify('Settings successfully saved', 'success');
          })
          .catch(error => notify(`Error: ${error.message}`, 'error'))
          .finally(() => setLoading(false));
  };

  const initialValues = {
    is_doubled_pdi_executed_transactions_skipped:
      loanAnomalyNotifications?.is_doubled_pdi_executed_transactions_skipped ?? false,
    is_doubled_pdi_scheduled_transactions_skipped:
      loanAnomalyNotifications?.is_doubled_pdi_scheduled_transactions_skipped ?? false,
    is_income_inconsistency_skipped: loanAnomalyNotifications?.is_income_inconsistency_skipped ?? false,
    is_loan_without_money_transfer_skipped: loanAnomalyNotifications?.is_loan_without_money_transfer_skipped ?? false,
    is_negative_commission_amount_skipped: loanAnomalyNotifications?.is_negative_commission_amount_skipped ?? false,
    is_negative_interest_amount_skipped: loanAnomalyNotifications?.is_negative_interest_amount_skipped ?? false,
    is_negative_pdi_amount_skipped: loanAnomalyNotifications?.is_negative_pdi_amount_skipped ?? false,
    is_negative_principal_amount_skipped: loanAnomalyNotifications?.is_negative_principal_amount_skipped ?? false,
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.wrapper}>
            <Field name="is_doubled_pdi_executed_transactions_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is doubled PDI executed transactions skipped"
                />
              )}
            </Field>
            <Field name="is_doubled_pdi_scheduled_transactions_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is doubled PDI scheduled transactions skipped"
                />
              )}
            </Field>
            <Field name="is_income_inconsistency_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is income inconsistency skipped"
                />
              )}
            </Field>
            <Field name="is_loan_without_money_transfer_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is loan without money transfer skipped"
                />
              )}
            </Field>
            <Field name="is_negative_commission_amount_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is negative commission amount skipped"
                />
              )}
            </Field>
            <Field name="is_negative_interest_amount_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is negative interest amount skipped"
                />
              )}
            </Field>
            <Field name="is_negative_pdi_amount_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is negative PDI amount skipped"
                />
              )}
            </Field>
            <Field name="is_negative_principal_amount_skipped">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox checked={input.value} onChange={input.onChange} />}
                  label="Is negative principal amount skipped"
                />
              )}
            </Field>
            <Button
              onClick={handleSubmit}
              className={classes.mt2}
              startIcon={loading ? <CircularProgress color="inherit" size={18} thickness={3} /> : <SaveIcon />}
              variant="contained"
              size="small"
              color="primary">
              Submit
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

AnomalyTriggersSettings.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
  refreshedAt: PropTypes.number,
};
