import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FormWrapper = ({ basePath, resource }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = React.useState(null);
  const [productCommissionStrategySettings, setProductCommissionStrategySettings] = React.useState([
    {
      tenor_from: null,
      tenor_to: null,
      principal_from: null,
      principal_to: null,
      commission_amount: null,
      commission_percent: null,
    },
  ]);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChangeName = e => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => name !== 'name')));
    setName(e.target.value || null);
  };

  const handleChangeRow = (e, i, field) => {
    const value = e.target.value;
    const errorsTemp = { ...errors };

    if (errorsTemp?.settings?.[i]?.[field]) {
      delete errorsTemp.settings[i][field];
    }
    if (errorsTemp?.settings?.[i] && Object.keys(errorsTemp.settings[i]).length === 0) {
      delete errorsTemp.settings[i];
    }
    if (errorsTemp?.settings && Object.keys(errorsTemp.settings).length === 0) {
      delete errorsTemp.settings;
    }
    if (field === 'commission_amount' && errorsTemp.settings?.[i]?.commission_percent) {
      delete errorsTemp.settings?.[i]?.commission_percent;
    }
    if (field === 'commission_percent' && errorsTemp.settings?.[i]?.commission_amount) {
      delete errorsTemp.settings?.[i]?.commission_amount;
    }
    if (
      field === 'tenor_from' &&
      errorsTemp.settings?.[i]?.tenor_other &&
      productCommissionStrategySettings[i].tenor_to > value
    ) {
      delete errorsTemp.settings?.[i]?.tenor_other;
    }
    if (
      field === 'tenor_to' &&
      errorsTemp.settings?.[i]?.tenor_other &&
      productCommissionStrategySettings[i].tenor_from <= value
    ) {
      delete errorsTemp.settings?.[i]?.tenor_other;
    }
    if (
      field === 'principal_from' &&
      errorsTemp.settings?.[i]?.principal_other &&
      productCommissionStrategySettings[i].principal_to > value
    ) {
      delete errorsTemp.settings?.[i]?.principal_other;
    }
    if (
      field === 'principal_to' &&
      errorsTemp.settings?.[i]?.principal_other &&
      productCommissionStrategySettings[i].principal_from <= value
    ) {
      delete errorsTemp.settings?.[i]?.principal_other;
    }

    setErrors(errorsTemp);
    setProductCommissionStrategySettings(prev => {
      const settings = [...prev];
      settings[i][field] = value || null;
      if (field === 'commission_amount') {
        settings[i].commission_percent = null;
      }
      if (field === 'commission_percent') {
        settings[i].commission_amount = null;
      }
      return settings;
    });
  };

  const handleRemoveClick = i => {
    const errorsTemp = { ...errors };
    if (errorsTemp.range?.length > 0) {
      delete errorsTemp.range;
    }
    const settings = [...productCommissionStrategySettings];
    settings.splice(i, 1);
    setProductCommissionStrategySettings(settings);
    setErrors(errorsTemp);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Field is required';
    } else if (name.length > 255) {
      formErrors.name = 'Maximum field length is 255 symbols';
    }

    formErrors.settings = {};
    productCommissionStrategySettings.forEach((s, i) => {
      if (!s.commission_amount && !s.commission_percent) {
        formErrors.settings[i] = {
          ...formErrors.settings[i],
          commission_amount: 'Any Commission field is required',
          commission_percent: 'Any Commission field is required',
        };
      }

      if (s.tenor_from === null || s.tenor_from === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_from: 'Field is required' };
      } else if (+s.tenor_from === 0) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_from: 'Tenor from cannot be 0' };
      }
      if (s.tenor_to === null || s.tenor_to === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], tenor_to: 'Field is required' };
      }
      if (s.tenor_from !== null && s.tenor_to !== null && +s.tenor_to < +s.tenor_from) {
        formErrors.settings[i] = {
          ...formErrors.settings[i],
          tenor_other: 'Tenor from should be less or equal to tenor to',
        };
      }

      if (s.principal_from === null || s.principal_from === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], principal_from: 'Field is required' };
      } else if (+s.principal_from === 0) {
        formErrors.settings[i] = { ...formErrors.settings[i], principal_from: 'Principal from cannot be 0' };
      }
      if (s.principal_to === null || s.principal_to === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], principal_to: 'Field is required' };
      }
      if (s.principal_from !== null && s.principal_to !== null && +s.principal_to < +s.principal_from) {
        formErrors.settings[i] = {
          ...formErrors.settings[i],
          principal_other: 'Principal from should be less or equal to principal to',
        };
      }
    });

    const rangeErrors = [];

    // --- TENOR RANGE VALIDATION ---
    const groupedTenorRanges = productCommissionStrategySettings.reduce((acc, setting) => {
      const key = `${setting.tenor_from}-${setting.tenor_to}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push({ ...setting });
      return acc;
    }, {});

    let lastTenorTo = 0;

    const tenorGroups = Object.entries(groupedTenorRanges).map(([tenorRange, settings]) => ({
      tenorRange,
      tenor_from: +settings[0].tenor_from,
      tenor_to: +settings[0].tenor_to,
      settings,
    }));

    tenorGroups.forEach(({ tenor_from, tenor_to }, index, arr) => {
      if (tenor_from > lastTenorTo + 1) {
        rangeErrors.push(`Missing tenor range from ${lastTenorTo + 1} to ${tenor_from - 1}`);
      }

      // Only check if next group's tenor_from is greater than the current tenor_to
      if (index < arr.length - 1 && tenor_to >= arr[index + 1].tenor_from && arr[index + 1].tenor_from !== 0) {
        rangeErrors.push(
          `'Tenor to' setting (${tenor_to}) must be strictly less than 'Tenor from' setting (${arr[index + 1].tenor_from}) in the next setting. Change 'Tenor from' setting to ${tenor_to + 1} or adjust the range.`,
        );
      }
      lastTenorTo = Math.max(lastTenorTo, tenor_to);
    });

    if (lastTenorTo < 1000) {
      rangeErrors.push(`Missing tenor range from ${lastTenorTo + 1} to 1000`);
    }

    // --- PRINCIPAL RANGE VALIDATION FOR EACH TENOR RANGE ---
    // Sort tenor groups by tenor_from
    const sortedTenorGroups = Object.entries(groupedTenorRanges)
      .map(([tenorRange, settings]) => ({
        tenorRange,
        tenor_from: +settings[0].tenor_from,
        tenor_to: +settings[0].tenor_to,
        settings,
      }))
      .sort((a, b) => a.tenor_from - b.tenor_from);

    sortedTenorGroups.forEach(({ tenorRange, settings }) => {
      settings.sort((a, b) => a.principal_from - b.principal_from);
      let lastPrincipalTo = 0;

      settings.forEach(({ principal_from, principal_to }, index, arr) => {
        if (+principal_from > +(lastPrincipalTo + 1)) {
          rangeErrors.push(
            `Missing principal range from ${lastPrincipalTo + 1} to ${principal_from - 1} for tenor range ${tenorRange.replace('null', 'N/D')}`,
          );
        }

        if (index > 0 && tenorRange !== 'null-null') {
          const prevPrincipalTo = arr[index - 1].principal_to;
          if (+principal_from <= +prevPrincipalTo) {
            rangeErrors.push(
              `Overlapping or incorrect principal ranges: 'Principal from' ${principal_from} should be greater than 'Principal to' ${prevPrincipalTo} in tenor range ${tenorRange}`,
            );
          }
        }

        lastPrincipalTo = Math.max(lastPrincipalTo, principal_to);
      });

      if (+lastPrincipalTo < 100000 && tenorRange !== 'null-null') {
        const tenorRangeArr = tenorRange.split('-');
        if (tenorRangeArr[0] === 'null') {
          tenorRangeArr[0] = 'N/D';
        } else if (tenorRangeArr[1] === 'null') {
          tenorRangeArr[1] = 'N/D';
        }
        const tenorRangeNew = `${tenorRangeArr[0]}-${tenorRangeArr[1]}`;
        rangeErrors.push(
          `Missing principal range from ${lastPrincipalTo + 1} to 100000 for tenor range ${tenorRangeNew}`,
        );
      }
    });

    // --- DUPLICATE SETTINGS VALIDATION ---
    const settingsMap = new Set();
    productCommissionStrategySettings.forEach(({ tenor_from, tenor_to, principal_from, principal_to }) => {
      if (tenor_from && tenor_to && principal_from && principal_to) {
        const key = `${tenor_from}-${tenor_to}-${principal_from}-${principal_to}`;
        if (settingsMap.has(key)) {
          rangeErrors.push(
            `Duplicate setting found: Tenor ${tenor_from}-${tenor_to}, Principal ${principal_from}-${principal_to}`,
          );
        }
        settingsMap.add(key);
      }
    });

    if (rangeErrors.length > 0) {
      formErrors.range = rangeErrors;
    }

    if (formErrors.settings && Object.keys(formErrors.settings).length === 0) {
      delete formErrors.settings;
    }

    const isValid = Object.keys(formErrors).length === 0;
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const settings = JSON.parse(JSON.stringify(productCommissionStrategySettings));
      settings.forEach(s => {
        s.tenor_from = +s.tenor_from;
        s.tenor_to = +s.tenor_to;
        s.principal_from = +s.principal_from;
        s.principal_to = +s.principal_to;
        if (s.commission_amount) {
          s.commission_amount = +s.commission_amount;
        }
        if (s.commission_percent) {
          s.commission_percent = parseFloat((+s.commission_percent / 100).toFixed(10));
        }
      });
      const payload = { name, product_commission_strategy_settings: settings };

      dataProvider
        .query(resource, { method: 'POST', body: JSON.stringify(payload) })
        .then(() => {
          setLoading(false);
          redirect(basePath);
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Create product commission strategy
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  {productCommissionStrategySettings.map((s, i) => (
                    <>
                      <Grid key={i} container spacing={1} flexDirection="row">
                        <Grid item xs={12} sm={4}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Tenor from"
                              value={s.tenor_from ?? ''}
                              onChange={e => handleChangeRow(e, i, 'tenor_from')}
                              error={!!errors?.settings?.[i]?.tenor_from}
                              helperText={errors?.settings?.[i]?.tenor_from || 'Included in the range'}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 1000, step: 1 },
                              }}
                              onKeyPress={event => {
                                if (
                                  event?.key === '-' ||
                                  event?.key === '+' ||
                                  event?.key === '.' ||
                                  event?.key === ','
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Tenor to"
                              value={s.tenor_to ?? ''}
                              onChange={e => handleChangeRow(e, i, 'tenor_to')}
                              error={!!errors?.settings?.[i]?.tenor_to}
                              helperText={errors?.settings?.[i]?.tenor_to || 'Included in the range'}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 1000, step: 1 },
                              }}
                              onKeyPress={event => {
                                if (
                                  event?.key === '-' ||
                                  event?.key === '+' ||
                                  event?.key === '.' ||
                                  event?.key === ','
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Principal from"
                              value={s.principal_from ?? ''}
                              onChange={e => handleChangeRow(e, i, 'principal_from')}
                              error={!!errors?.settings?.[i]?.principal_from}
                              helperText={errors?.settings?.[i]?.principal_from || 'Included in the range'}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 100000, step: 1 },
                              }}
                              onKeyPress={event => {
                                if (
                                  event?.key === '-' ||
                                  event?.key === '+' ||
                                  event?.key === '.' ||
                                  event?.key === ','
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Principal to"
                              value={s.principal_to ?? ''}
                              onChange={e => handleChangeRow(e, i, 'principal_to')}
                              error={!!errors?.settings?.[i]?.principal_to}
                              helperText={errors?.settings?.[i]?.principal_to || 'Included in the range'}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 100000, step: 1 },
                              }}
                              onKeyPress={event => {
                                if (
                                  event?.key === '-' ||
                                  event?.key === '+' ||
                                  event?.key === '.' ||
                                  event?.key === ','
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Commission amount"
                              value={s.commission_amount ?? ''}
                              onChange={e => handleChangeRow(e, i, 'commission_amount')}
                              error={!!errors?.settings?.[i]?.commission_amount}
                              helperText={errors?.settings?.[i]?.commission_amount || ' '}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 10000, step: 0.1 },
                              }}
                              onKeyPress={event => {
                                if (event?.key === '-' || event?.key === '+') {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Commission %"
                              value={s.commission_percent ?? ''}
                              onChange={e => handleChangeRow(e, i, 'commission_percent')}
                              error={!!errors?.settings?.[i]?.commission_percent}
                              helperText={errors?.settings?.[i]?.commission_percent || ' '}
                              size="small"
                              InputProps={{
                                inputProps: { min: 0, max: 100, step: 0.1 },
                              }}
                              onKeyPress={event => {
                                if (event?.key === '-' || event?.key === '+') {
                                  event.preventDefault();
                                }
                              }}
                              className={classes.formControl}
                            />
                          </Box>
                        </Grid>
                        {i !== 0 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton color="primary" onClick={() => handleRemoveClick(i)}>
                              <RemoveCircleOutlineTwoTone />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      {errors?.settings?.[i]?.tenor_other && (
                        <Box>
                          <Typography variant="caption" color="error">
                            {errors?.settings?.[i]?.tenor_other}
                          </Typography>
                        </Box>
                      )}
                      {errors?.settings?.[i]?.principal_other && (
                        <Box>
                          <Typography variant="caption" color="error">
                            {errors?.settings?.[i]?.principal_other}
                          </Typography>
                        </Box>
                      )}
                      <Divider className={classes.my2} />
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" variant="caption" color="textSecondary">
                    For correctly saving the strategy, it is necessary to fill in all ranges of the terms from 1 to 1000
                    and all ranges of the principals from 1 to 100000
                  </Typography>
                </Grid>
                {errors.range?.length > 0 && (
                  <Grid item xs={12}>
                    {errors.range.map((error, i) => (
                      <Typography component="p" key={i} variant="caption" color="error">
                        {error}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddCircleOutlineTwoTone />}
                    onClick={() => {
                      const errorsTemp = { ...errors };
                      if (errorsTemp.range?.length > 0) {
                        delete errorsTemp.range;
                      }
                      setErrors(errorsTemp);
                      setProductCommissionStrategySettings(prev => [
                        ...prev,
                        {
                          tenor_from: null,
                          tenor_to: null,
                          principal_from: null,
                          principal_to: null,
                          commission_amount: null,
                          commission_percent: null,
                        },
                      ]);
                    }}>
                    Add new row
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
              <Button variant="outlined" color="primary" startIcon={<CloseIcon />} onClick={() => redirect(basePath)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

FormWrapper.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
};

const ProductCommissionStrategiesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default ProductCommissionStrategiesCreate;
