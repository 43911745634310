import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  TextInput,
  NumberInput,
  minValue,
  maxValue,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '../../components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <FormWithRedirect
      save={({ is_enabled, name, admin_id, audience_id, priority }, ...rest) => {
        save(
          ...[
            {
              is_enabled,
              name,
              admin_id,
              audience_id,
              priority,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Typography variant="h6" gutterBottom={false}>
                      Update admin assignment rule
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BooleanInput label="Enabled" source="is_enabled" helperText={false} />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      label="Audience"
                      resource="mass_sending_audiences"
                      source="audience_id"
                      optionValueProp="id"
                      optionLabelProp="name"
                      required
                      withClickableOptions
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      label="Admin"
                      resource="admins"
                      source="admin_id"
                      optionValueProp="id"
                      optionLabelProp="username"
                      filter={{
                        role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
                        is_blocked: false,
                      }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberInput
                      source="priority"
                      min={1}
                      max={65535}
                      step={1}
                      validate={[required(), minValue(1), maxValue(65535)]}
                      fullWidth
                      helperText={false}
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => redirect(formProps.basePath)}>
                    Cancel
                  </Button>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const AdminAssignmentRulesEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default AdminAssignmentRulesEdit;
