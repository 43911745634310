import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { marginZeroStyles } from '../../constants';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const AssignmentRulesList = props => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'priority', order: 'ASC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="priority" sortable={false} />
        <BooleanField source="is_enabled" sortable={false} />
        <FunctionField
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
          sortable={false}
        />
        <FunctionField
          source="updated_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default AssignmentRulesList;
