import React from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minValue,
  maxValue,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <FormWithRedirect
      save={(
        {
          name,
          loan_principal_percent,
          outstanding_principal_percent,
          outstanding_interest_percent,
          outstanding_commission_percent,
          outstanding_pdi_percent,
          extension_interest_percent,
        },
        ...rest
      ) => {
        const payload = {
          name,
          loan_principal_percent,
          outstanding_principal_percent,
          outstanding_interest_percent,
          outstanding_commission_percent,
          outstanding_pdi_percent,
          extension_interest_percent,
        };

        save(...[payload, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create product extension payment strategy
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth helperText={false} />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        label="Loan principal percent, %"
                        source="loan_principal_percent"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        label="Outstanding principal percent, %"
                        source="outstanding_principal_percent"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        source="outstanding_interest_percent"
                        label="Outstanding interest percent, %"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        source="outstanding_commission_percent"
                        label="Outstanding commission percent, %"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        source="outstanding_pdi_percent"
                        label="Outstanding PDI percent, %"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumberInput
                        helperText={false}
                        min={0}
                        max={100}
                        source="extension_interest_percent"
                        label="Extension interest percent, %"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => redirect(formProps.basePath)}>
                    Cancel
                  </Button>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ProductExtensionPaymentStrategiesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default ProductExtensionPaymentStrategiesCreate;
