import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
}));

const FormWrapper = ({ basePath, resource }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = React.useState(null);
  const [productPdiRateStrategySettings, setProductPdiRateStrategySettings] = React.useState([
    { rate: null, pdi_add_from_term: null, pdi_add_to_term: null },
  ]);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChangeName = e => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => name !== 'name')));
    setName(e.target.value || null);
  };

  const handleChangeRow = (e, i, field) => {
    const value = e.target.value;
    const errorsTemp = { ...errors };

    if (errorsTemp?.settings?.[i]?.[field]) {
      delete errorsTemp.settings[i][field];
    }
    if (errorsTemp?.settings?.[i] && Object.keys(errorsTemp.settings[i]).length === 0) {
      delete errorsTemp.settings[i];
    }
    if (errorsTemp?.settings && Object.keys(errorsTemp.settings).length === 0) {
      delete errorsTemp.settings;
    }
    if (
      field === 'pdi_add_from_term' &&
      errorsTemp.settings?.[i]?.other &&
      productPdiRateStrategySettings[i].pdi_add_to_term > value
    ) {
      delete errorsTemp.settings?.[i]?.other;
    }
    if (
      field === 'pdi_add_to_term' &&
      errorsTemp.settings?.[i]?.other &&
      productPdiRateStrategySettings[i].pdi_add_from_term <= value
    ) {
      delete errorsTemp.settings?.[i]?.other;
    }

    setErrors(errorsTemp);
    setProductPdiRateStrategySettings(prev => {
      const settings = [...prev];
      settings[i][field] = value || null;
      return settings;
    });
  };

  const handleRemoveClick = i => {
    const errorsTemp = { ...errors };
    if (errorsTemp.range?.length > 0) {
      delete errorsTemp.range;
    }
    const settings = [...productPdiRateStrategySettings];
    settings.splice(i, 1);
    setProductPdiRateStrategySettings(settings);
    setErrors(errorsTemp);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Field is required';
    } else if (name.length > 255) {
      formErrors.name = 'Maximum field length is 255 symbols';
    }

    formErrors.settings = {};
    productPdiRateStrategySettings.forEach((s, i) => {
      if (!s.rate) {
        formErrors.settings[i] = { ...formErrors.settings[i], rate: 'Field is required' };
      }
      if (s.pdi_add_from_term === null || s.pdi_add_from_term === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], pdi_add_from_term: 'Field is required' };
      } else if (+s.pdi_add_from_term === 0) {
        formErrors.settings[i] = { ...formErrors.settings[i], pdi_add_from_term: 'PDI add from term cannot be 0' };
      }
      if (s.pdi_add_to_term === null || s.pdi_add_to_term === undefined) {
        formErrors.settings[i] = { ...formErrors.settings[i], pdi_add_to_term: 'Field is required' };
      }
      if (s.pdi_add_from_term !== null && s.pdi_add_to_term !== null && +s.pdi_add_to_term < +s.pdi_add_from_term) {
        formErrors.settings[i] = {
          ...formErrors.settings[i],
          other: 'PDI add from term should be less or equal to tenor to',
        };
      }
    });

    const sortedSettings = [...productPdiRateStrategySettings]
      .filter(s => s.pdi_add_from_term !== null && s.pdi_add_to_term !== null)
      .sort((a, b) => +a.pdi_add_from_term - +b.pdi_add_from_term);

    let lastPdiAddToTerm = 0;
    const rangeErrors = [];

    sortedSettings.forEach((setting, index) => {
      const { pdi_add_from_term, pdi_add_to_term } = setting;

      if (index > 0) {
        const prevTenorTo = +sortedSettings[index - 1].pdi_add_to_term;
        const prevTenorFrom = +sortedSettings[index - 1].pdi_add_from_term;
        if (+pdi_add_from_term < prevTenorTo) {
          rangeErrors.push(
            `Overlapping range: 'PDI add from term' ${pdi_add_from_term} overlaps with the previous range ${prevTenorFrom}-${prevTenorTo}`,
          );
        } else if (+pdi_add_from_term === prevTenorTo) {
          rangeErrors.push(
            `'PDI add from term' ${pdi_add_from_term} is equal to one of 'Tenor to' values - change 'PDI add from term' value from ${pdi_add_from_term} to ${+pdi_add_from_term + 1}`,
          );
        }
      }

      if (+pdi_add_from_term > lastPdiAddToTerm + 1) {
        rangeErrors.push(`Missing range from ${lastPdiAddToTerm + 1} to ${pdi_add_from_term - 1}`);
      }

      lastPdiAddToTerm = Math.max(lastPdiAddToTerm, +pdi_add_to_term);
    });

    if (lastPdiAddToTerm < 1000) {
      rangeErrors.push(`Missing range from ${lastPdiAddToTerm + 1} to 1000`);
    }

    if (rangeErrors.length > 0) {
      formErrors.range = rangeErrors;
    }

    if (formErrors.settings && Object.keys(formErrors.settings).length === 0) {
      delete formErrors.settings;
    }

    const isValid = Object.keys(formErrors).length === 0;
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const settings = JSON.parse(JSON.stringify(productPdiRateStrategySettings));
      settings.forEach(s => {
        s.rate = parseFloat((+s.rate / 100).toFixed(10));
        s.pdi_add_from_term = +s.pdi_add_from_term;
        s.pdi_add_to_term = +s.pdi_add_to_term;
      });
      const payload = { name, product_pdi_rate_strategy_settings: settings };

      dataProvider
        .query(resource, { method: 'POST', body: JSON.stringify(payload) })
        .then(() => {
          setLoading(false);
          redirect(basePath);
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Create product PDI rate strategy
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    multiline
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  {productPdiRateStrategySettings.map((s, i) => (
                    <>
                      <Grid key={i} container spacing={1} flexDirection="row">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="Rate"
                            value={s.rate ?? ''}
                            onChange={e => handleChangeRow(e, i, 'rate')}
                            error={!!errors?.settings?.[i]?.rate}
                            helperText={errors?.settings?.[i]?.rate || '% per day'}
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 10, step: 0.01 },
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="PDI add from term"
                            value={s.pdi_add_from_term ?? ''}
                            onChange={e => handleChangeRow(e, i, 'pdi_add_from_term')}
                            error={!!errors?.settings?.[i]?.pdi_add_from_term}
                            helperText={
                              errors?.settings?.[i]?.pdi_add_from_term || 'This value is included in the range'
                            }
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 1000, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (
                                event?.key === '-' ||
                                event?.key === '+' ||
                                event?.key === '.' ||
                                event?.key === ','
                              ) {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            type="number"
                            variant="filled"
                            label="PDI add to term"
                            value={s.pdi_add_to_term ?? ''}
                            onChange={e => handleChangeRow(e, i, 'pdi_add_to_term')}
                            error={!!errors?.settings?.[i]?.pdi_add_to_term}
                            helperText={errors?.settings?.[i]?.pdi_add_to_term || 'This value is included in the range'}
                            size="small"
                            InputProps={{
                              inputProps: { min: 0, max: 1000, step: 1 },
                            }}
                            onKeyPress={event => {
                              if (
                                event?.key === '-' ||
                                event?.key === '+' ||
                                event?.key === '.' ||
                                event?.key === ','
                              ) {
                                event.preventDefault();
                              }
                            }}
                            className={classes.formControl}
                          />
                        </Grid>
                        {i !== 0 && (
                          <Grid item xs={12} sm={1}>
                            <IconButton color="primary" onClick={() => handleRemoveClick(i)}>
                              <RemoveCircleOutlineTwoTone />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                      {errors?.settings?.[i]?.other && (
                        <Typography variant="caption" color="error">
                          {errors?.settings?.[i]?.other}
                        </Typography>
                      )}
                    </>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography component="p" variant="caption" color="textSecondary">
                    For correctly saving the strategy, it is necessary to fill in all ranges of the terms from 1 to 1000
                  </Typography>
                </Grid>
                {errors.range?.length > 0 && (
                  <Grid item xs={12}>
                    {errors.range.map((error, i) => (
                      <Typography component="p" key={i} variant="caption" color="error">
                        {error}
                      </Typography>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddCircleOutlineTwoTone />}
                    onClick={() => {
                      const errorsTemp = { ...errors };
                      if (errorsTemp.range?.length > 0) {
                        delete errorsTemp.range;
                      }
                      setErrors(errorsTemp);
                      setProductPdiRateStrategySettings(prev => [
                        ...prev,
                        { rate: null, pdi_add_from_term: null, pdi_add_to_term: null },
                      ]);
                    }}>
                    Add new row
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
              <Button variant="outlined" color="primary" startIcon={<CloseIcon />} onClick={() => redirect(basePath)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

FormWrapper.propTypes = {
  resource: PropTypes.string,
  basePath: PropTypes.string,
};

const ProductPdiRateStrategiesCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);

export default ProductPdiRateStrategiesCreate;
