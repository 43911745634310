import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  BooleanInput,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  useDataProvider,
  useNotify,
  TextInput,
  NumberInput,
  minValue,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { useHandbook } from '../../hooks';
import { phoneNumber } from '../../utils';
import { CheckboxListInput } from '../../components';
import ChangePasswordDialog from './ChangePasswordDialog';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  },
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={2}>{children}</Box>}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

const FormWrapper = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [roles, setRoles] = useState(null);
  const [phone, setPhone] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const isEditable = permissions.indexOf('CAN_ADMIN_EDIT') !== -1;
  const canAssignSecured = permissions.includes('CAN_ASSIGN_SECURE_ROLES');
  const canAssignRegular = permissions.includes('CAN_ASSIGN_REGULAR_ROLES');
  const canChangePassword = permissions.includes('CAN_CHANGE_ADMIN_PASSWORD');

  const handleChange = (e, value) => setActiveTab(() => value);

  const { data: collectionGroupsResponse } = useHandbook('collection_groups', { items_per_page: 30 });

  const collectionGroups = collectionGroupsResponse.map(group => ({
    id: group.code,
    name: group.code,
  }));

  const collectionGroupsVievs = collectionGroupsResponse.map(group => ({
    id: group.id,
    name: group.code,
  }));

  useEffect(() => {
    props.record.phone && setPhone(props.record.phone);
  }, [props]);

  useEffect(() => {
    if (phone?.includes('+52')) {
      setIsPhoneValid(phoneNumber.validator(phoneNumber.parser(phone)));
    } else {
      setIsPhoneValid(phoneNumber.validator(phone));
    }
  }, [phone]);

  useEffect(() => {
    const firstRoles = ['ROLE_SUPER_ADMIN', 'ROLE_CEO', 'ROLE_BASIC_VIEW', 'ROLE_EXTENDED_VIEW'];

    dataProvider
      .getList('admin_auth_roles', {
        filter: { is_enabled: true },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      })
      .then(({ data }) => {
        const roles = data.map(role => ({
          id: role.code,
          level: role.level,
          name: role.name,
        }));

        roles.sort(function (a, b) {
          const aIndex = firstRoles.indexOf(a.id);
          const bIndex = firstRoles.indexOf(b.id);

          if (aIndex === -1 && bIndex === -1) {
            return 0;
          }

          if (aIndex === -1) {
            return 1;
          }

          if (bIndex === -1) {
            return -1;
          }

          return Math.sign(aIndex - bIndex);
        });

        setRoles(roles);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  const onChangePassword = payload => {
    setShowChangePassword(false);
    dataProvider
      .query(`admins/password/${props.record.id}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
      .then(() => notify('Password succesfully updated', 'success'))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onCloseChangePasswordDialog = () => {
    setShowChangePassword(false);
  };

  if (loading)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={false}>
                      {formProps.record.username}
                    </Typography>
                    <BooleanInput
                      disabled={!isEditable}
                      label="Blocked"
                      source="is_blocked"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextInput
                      source="phone"
                      helperText="Optional field"
                      placeholder={phoneNumber.placeholder}
                      parse={phoneNumber.parser}
                      format={v => phoneNumber.formatter(v)}
                      onChange={event => setPhone(event.target.value)}
                    />
                    <BooleanInput
                      disabled={!isPhoneValid}
                      label="Send SMS about loans"
                      source="is_send_sms_about_loans"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <NumberInput
                        source="max_approved_amount"
                        label="Max approve amount, MXN"
                        helperText="Optional field"
                        fullWidth
                        min={0}
                        validate={minValue(0, 'The value must be a positive number')}
                      />
                    </Grid>
                    <BooleanInput
                      label="Allow not to change password"
                      source="is_allow_not_change_password"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <NumberInput
                        source="webitel_extension"
                        helperText="Optional field"
                        fullWidth
                        min={0}
                        validate={minValue(0, 'The value must be a positive number')}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                </Box>
                {!canAssignSecured && !canAssignRegular ? (
                  <>
                    <Tabs value={activeTab} onChange={handleChange}>
                      <Tab label="Collection groups" />
                      <Tab label="Collection groups viewing" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                      <CheckboxListInput
                        isEditable={isEditable}
                        source="collection_groups"
                        choices={collectionGroups}
                      />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <CheckboxListInput
                        isEditable={isEditable}
                        source="collection_group_views"
                        choices={collectionGroupsVievs}
                        withObjects
                      />
                    </TabPanel>
                  </>
                ) : (
                  <>
                    <Tabs value={activeTab} onChange={handleChange}>
                      <Tab label="Admin roles" />
                      <Tab label="Collection groups" />
                      <Tab label="Collection groups viewing" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                      <CheckboxListInput
                        permissions={permissions}
                        isEditable={isEditable}
                        source="roles"
                        choices={roles}
                      />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <CheckboxListInput
                        isEditable={isEditable}
                        source="collection_groups"
                        choices={collectionGroups}
                      />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                      <CheckboxListInput
                        isEditable={isEditable}
                        source="collection_group_views"
                        choices={collectionGroupsVievs}
                        withObjects
                      />
                    </TabPanel>
                  </>
                )}
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    disabled={!isEditable}
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  {canChangePassword && (
                    <Button variant="outlined" color="primary" onClick={() => setShowChangePassword(true)}>
                      Change password
                    </Button>
                  )}
                </Toolbar>
                <ChangePasswordDialog
                  isOpened={showChangePassword}
                  onClose={onCloseChangePasswordDialog}
                  onSubmit={onChangePassword}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  record: PropTypes.shape({
    phone: PropTypes.string,
    id: PropTypes.number,
  }),
};

const AdminEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default AdminEdit;
