import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FormWrapper = ({ record, basePath, resource }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = React.useState(record.name);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleChangeName = e => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => name !== 'name')));
    setName(e.target.value || null);
  };

  const validate = () => {
    const formErrors = {};

    if (!name) {
      formErrors.name = 'Field is required';
    } else if (name.length > 255) {
      formErrors.name = 'Maximum field length is 255 symbols';
    }

    const isValid = Object.keys(formErrors).length === 0;
    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      dataProvider
        .query(`${resource}/${record.id}`, { method: 'PATCH', body: JSON.stringify({ name }) })
        .then(() => {
          setLoading(false);
          redirect(basePath);
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom={false}>
                  Update product commission strategy
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.my2} />
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formControl}
                    variant="filled"
                    label="Name"
                    value={name || ''}
                    onChange={handleChangeName}
                    error={!!errors.name}
                    helperText={errors.name}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  {record.product_commission_strategy_settings.map((s, i) => (
                    <>
                      <Grid key={i} container spacing={1} flexDirection="row">
                        <Grid item xs={12} sm={4}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Tenor from"
                              value={s.tenor_from ?? ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Tenor to"
                              value={s.tenor_to ?? ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Principal from"
                              value={s.principal_from ?? ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Principal to"
                              value={s.principal_to ?? ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box className={classes.flexColumn}>
                            <TextField
                              type="number"
                              variant="filled"
                              label="Commission amount"
                              value={s.commission_amount ?? ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                            <TextField
                              type="number"
                              variant="filled"
                              label="Commission percent"
                              value={s.commission_percent ? parseFloat((s.commission_percent * 100).toFixed(10)) : ''}
                              size="small"
                              className={classes.formControl}
                              disabled
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
            </Box>
            <Divider className={classes.my2} />
            <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
              <Button variant="outlined" color="primary" startIcon={<CloseIcon />} onClick={() => redirect(basePath)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                type="submit">
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

FormWrapper.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    product_commission_strategy_settings: PropTypes.array,
  }),
  resource: PropTypes.string,
  basePath: PropTypes.string,
};

const ProductCommissionStrategiesEdit = ({ ...props }) => (
  <Edit component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default ProductCommissionStrategiesEdit;
